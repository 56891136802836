import {Route, Switch, Redirect} from 'react-router-dom';
import CheckoutScene from './scenes/checkout';
import ErrorView from './components/ErrorView';
import SigningComplete from './scenes/signingComplete';
import './css/app.scss';
import TermsView from './scenes/terms/termsView';
import GdprTermsView from './scenes/terms/gdprTermsView';

function App(props) {

    return (
        <div className="app-container">
            <Switch>
                <Route path="/checkout" component={CheckoutScene}/>
                <Route path="/signature" exact component={SigningComplete}/>
                <Route path="/errorview" render={() => <ErrorView description=" " hideTryAgain title="404 Sidan finns inte"/>}/>
                {/* <Route path="/signing-complete" component={SigningCompleteScene} /> */}
                <Route path="/terms/:partner_id/:insurance_value" render={(props) => <TermsView {...props} />}/>
                <Route path="/gdprTerms" render={(props) => <GdprTermsView {...props} />}/>
                <Route path="/">
                    <Redirect to="/errorview"/>
                </Route>
            </Switch>
        </div>
    );
}

export default App;
