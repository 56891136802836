import React, {useState, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import './style.scss';
import '../../css/columns.scss';

import WasaLogo from '../../assets/logo.svg'
import bankIdLogo from '../../assets/bankid_vector_rgb.svg'

const TermsScene = (props) => {

    return (                                    
                <div className="terms-container">
                   <div id="wasa-kredit-terms" className="partner-terms">
                        <span>Genom att slutföra köpet godkänner jag </span>
                        <NavLink to={`/terms/${props.partnerId}/${props.insuranceValue}`} target="_blank" ><nobr>allmänna villkor</nobr></NavLink>
                        ,&#160;
                        <a id="cookie-policy" href='http://www.wasakredit.se/om-wasa-kredit/cookies' target="_blank">cookiepolicy</a>                        
                        <span> och hantering av </span>
                        <NavLink to={`/gdprTerms`} target="_blank" >personuppgifter</NavLink>
                        <span>.</span>
                        <span className='contact-us'> Kontakta oss: <a href='mailto:ehandel@wasakredit.se'>ehandel@wasakredit.se</a></span>
                    </div>
                    <div className="logo-container">
                            <img src={bankIdLogo} height="100" id="bankIdLogo" alt="BankId Logo" />
                            <img src={WasaLogo} height="70" id="wasalogo" alt="Wasa Kredit Logo" />       
                    </div>
                </div>
    );
}
export default TermsScene;