import React from 'react'
import './style.scss';
import enhorning from "./../../assets/enhorning.png"
import DisclaimerInfo from "../DisclaimerInfo";

const ErrorView = (props) => {
    return (
        <div id="errorView" className="error-container">
            <DisclaimerInfo/> 
            <div className="info-container">
                <img className="unicorn" alt="Enhörning" src={enhorning}/>
                <h1>
                    <span className="status-code">{props.title!==undefined? props.title : "Oj!"}</span> {props.description!==undefined? props.description : " Nu gick något fel!"}
                </h1>
                <h2>
                    {props.hideTryAgain ||  "Var god ladda om sidan och försök igen. "}
                </h2>
                {props.hideTryAgain || <span className='contact-us'>Kontakta oss: <a href='mailto:ehandel@wasakredit.se'>ehandel@wasakredit.se</a></span>}
            </div>
        </div>
    )
}
export default ErrorView
  