import React, { useState, useEffect } from 'react';
import {getGdprTerms} from "../../../services/api";
import ErrorView from '../../../components/ErrorView';

import "../termsView/style.scss";

const GdprTermsView = (props) => {
    const [hasError, setHasError] = useState(false);
    const [terms, setTerms] = useState({terms: null});
    useEffect(() => {
        getGdprTerms().then(res => {
            setTerms({
                terms: res.terms
            });
        }).catch(err => {
            console.log("Error fetching terms", err);
            setHasError(true);
        });

    }, [])

  return (
    hasError ? <ErrorView/>
    :
    <div  className="terms-window">
        <div dangerouslySetInnerHTML={{__html: terms.terms}}/>
    </div>
  );
}

export default GdprTermsView;