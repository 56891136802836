const currencyFormat = currency => {
    switch(currency.toUpperCase()) {
      case "EUR":
        return { prefix: "€", suffix: "" };
      case "GBP":
        return { prefix: "£", suffix: "" };
      case "SEK":
        return { prefix: "", suffix: " kr" };
      case "USD":
        return { prefix: "$", suffix: "" };
      default:
        return { prefix: "", suffix: "" };
    }
  };
  
  const formatThousandDelimiter = (number) => {
    if (!number) return 0;
    const thousandDilimiter = /\B(?=(\d{3})+(?!\d))/g;

    return number.toString().replace(thousandDilimiter, " ");
}


  const round = number => {
    var factor = Math.pow(10, 0);
    return Math.round(number * factor) / factor;
  };
  
  const currencyAux = (amountTransformer) => {
    return (amount, currency) => {
      const parsedAmount = (amountTransformer !== undefined ? amountTransformer(amount) : amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      const c = currencyFormat(currency);
      return `${c.prefix}${parsedAmount}${c.suffix}`;
    }
  };

  const convertNumberToText = (p) => {
    const t= [
      "noll",
      "en",
      "två",
      "tre",
      "fyra",
      "fem",
      "sex",
      "sju",
      "åtta",
      "nio",
      "tio",
      "elva",
      "tolv",
      "tretton",
      "fjorton",
      "femton",
      "sexton"
    ];
    if(p > 16){
      return "not defined";
    }
    return t[p];
  };

  export default {
    currency: currencyAux(round),
    formatThousandDelimiter: formatThousandDelimiter,
    numberToText: convertNumberToText,
    currencyNoRound: currencyAux(),
    currencyToFixed: (fractionDigits) => currencyAux(val => typeof val == 'number' ? val.toFixed(fractionDigits) : val),
  }
  

  
  