export const getIdFromQueryString = () => {
    return GetValueFromQueryString("id");
};
  
export const GetValueFromQueryString = (id)=>{
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(id);
}

export const cleanPhoneString = (value) => {
    if (value)
    {
        return value.replace(/[^0-9 \-+]|^[^0-9+]|^(.+)\+|([ \-+])[ \-+]*/gi,'$1$2')
    }
    return "";
};

export const cleanString = (value) => {
    return value.replace(/^[^a-zåäö1-9]|(\s)\s*/gi,'$1');
};

export const isObjectsEqual=(a, b)=>{
    return JSON.stringify(a) === JSON.stringify(b);
}



export const makePropertyValueArray = (obj) => {
    //makes [{property:'street',value:'downing street'}, {property:'streetNumber',value:'downing street'}] 
    //out of {street:'downing street', streetNumber:'18'}
    //so you can use filter, map and other cool stuff instead of Object.getOwnProp....
    let res = [];
    Object.getOwnPropertyNames(obj).forEach(key => {
        res.push({property: key, value: obj[key]});
    })
    return res;
}